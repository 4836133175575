import React, { useState } from 'react';
import { Handle, NodeToolbar } from '@xyflow/react';
import { IconButton, Typography, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NodesDynamicForm from '../../../../forms/NodesDynamicForm';
import nodeTypesConfig, { conditionTypes } from '../../../../models/nodeTypesConfig';
import { useContext } from 'react';
import AddNodeContext from '../../../../contexts/AddNodeContext';
import NodeConfigModal from '../../menus/NodeConfigModal';

const ConditionNode = ({ id, type, data, selected }) => {
    const [configModal, setConfigModal] = useState({ open: false });
    const { editNode } = useContext(AddNodeContext);

    const handleEdit = () => {
        const nodeConfig = nodeTypesConfig.actions.find(
            (node) => node.type === type
        );

        setConfigModal({
            open: true,
            content: (
                <NodesDynamicForm
                    title={`Edit "${nodeConfig?.displayName}" Node`}
                    formFields={nodeConfig?.formFields || []}
                    initialData={data}
                    onSubmit={(formData) => {
                        editNode(id, formData);
                        setConfigModal({ open: false });
                    }}
                />
            ),
        });
    };

    // Helper function to format individual conditions
    const formatCondition = (condition) => {
        if (!condition.type || !condition.value) return '';

        const conditionType = conditionTypes[condition.type];
        if (!conditionType) return '';

        const option = conditionType.options.find(opt => opt.value === condition.value);
        const actionType = condition.isWhitelist ? 'IS' : 'IS NOT';

        return `${actionType} ${option?.label || condition.value}`;
    };

    // Format all conditions into a readable string
    const formatConditions = () => {
        if (!data.conditions || !Array.isArray(data.conditions)) {
            return 'No conditions set';
        }

        return data.conditions.map((condition, index) => {
            const formattedCondition = formatCondition(condition);
            if (index === 0) return formattedCondition;
            return ` ${condition.operator} ${formattedCondition}`;
        }).join('');
    };

    return (
        <>
            <NodeToolbar
                isVisible={selected}
                position="top"
                offset={10}
            >
                <IconButton
                    onClick={handleEdit}
                    size="small"
                    sx={{
                        bgcolor: 'background.paper',
                        '&:hover': { bgcolor: 'action.hover' }
                    }}
                >
                    <EditIcon fontSize="small" />
                </IconButton>
            </NodeToolbar>

            <div style={{
                padding: 10,
                border: '1px solid #777',
                borderRadius: 5,
                backgroundColor: '#fff3e0',
                minWidth: 200,
                maxWidth: 400,
                position: 'relative',
            }}>
                <Handle type="target" position="top" />
                <Box sx={{ mb: 1 }}>
                    <Typography variant="subtitle2" align="center" sx={{ fontWeight: 'bold' }}>
                        Condition Node
                    </Typography>
                </Box>
                <Typography
                    variant="body2"
                    align="center"
                    sx={{
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap'
                    }}
                >
                    {formatConditions()}
                </Typography>
                <Handle
                    type="source"
                    position="bottom"
                    id="yes"
                    style={{ left: 40 }}
                />
                <Handle
                    type="source"
                    position="bottom"
                    id="no"
                    style={{ left: 'auto', right: 40 }}
                />
            </div>

            {configModal.open && (
                <NodeConfigModal
                    open={configModal.open}
                    onClose={() => setConfigModal({ open: false })}
                >
                    {configModal.content}
                </NodeConfigModal>
            )}
        </>
    );
};

export default ConditionNode;
