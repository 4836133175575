//These are for the rest of the nodes
import ContactMailIcon from "@mui/icons-material/ContactMail";
import SmsIcon from "@mui/icons-material/Sms";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AddIcon from "@mui/icons-material/Add";
import EmailIcon from "@mui/icons-material/Email";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

//nodes
import StayedTimeOnPageNode from "../components/flow/nodes/triggers/StayedTimeOnPageNode";
import NewLeadNode from "../components/flow/nodes/triggers/NewLeadNode";
import ExitNode from "../components/flow/nodes/ExitNode";
import ConditionNode from "../components/flow/nodes/actions/ConditionNode";
import WaitNode from "../components/flow/nodes/actions/WaitNode";
import WhatsAppMessageNode from "../components/flow/nodes/actions/WhatsAppMessageNode";
import EmailMessageNode from "../components/flow/nodes/actions/EmailMessageNode";
import SMSMessageNode from "../components/flow/nodes/actions/SMSMessageNode";
import AddedToSegment from "../components/flow/nodes/triggers/AddedToSegment";

//Implement hook to get info from user (for "send from" in messsage node, etc.)
// Add this before the nodeTypesConfig object
export const conditionTypes = {
  leadType: {
    label: "Lead Type",
    options: [
      { value: "basic", label: "Basic Lead" },
      { value: "qualified", label: "Qualified Lead" },
      { value: "customer", label: "Customer" },
    ],
  },
  campaign: {
    label: "Campaign",
    options: [
      { value: "camp1", label: "Summer Sale 2024" },
      { value: "camp2", label: "New Product Launch" },
      { value: "camp3", label: "Retention Campaign" },
    ],
  },
  emailOptIn: {
    label: "Email Opt-in Status",
    options: [
      { value: "opted_in", label: "Opted In" },
      { value: "opted_out", label: "Opted Out" },
      { value: "pending", label: "Pending" },
    ],
  },
};
let nodeTypesConfig = {
  triggers: [
    {
      type: "stayedTimeOnPage",
      displayName: "Stayed X time on Page",
      icon: AccessTimeIcon,
      iconColor: "#ffa726",
      formFields: [
        {
          id: "timeUnit",
          label: "Time Unit",
          type: "select",
          required:true,
          options: [
            { value: "minutes", label: "Minutes" },
            { value: "hours", label: "Hours" },
            { value: "days", label: "Days" },
          ],
        },
        {
          id: "timeAmount",
          label: "Amount",
          type: "number",
          required:true,
          min: 1,
        },
        { id: "sendTo", label: "Send To", type: "text", required:true },
        {
          id: "hoursToSkip",
          label: "Hours to Skip",
          type: "timeRange",
          defaultValue: ["09:00", "17:00"],
        },
        {
          id: "daysToSkip",
          label: "Days to Skip",
          type: "checkboxGroup",
          options: [
            { value: "sunday", label: "Sunday" },
            { value: "monday", label: "Monday" },
            { value: "tuesday", label: "Tuesday" },
            { value: "wednesday", label: "Wednesday" },
            { value: "thursday", label: "Thursday" },
            { value: "friday", label: "Friday" },
            { value: "saturday", label: "Saturday" },
          ],
        },
      ],
      nodeComponent: StayedTimeOnPageNode,
    },
    {
      type: "newLead",
      displayName: "New Lead",
      icon: ContactMailIcon,
      iconColor: "#66bb6a",
      formFields: [
        {
          id: "leadType",
          label: "Lead Type",
          type: "select",
          required: true,
          options: [
            { value: 1, label: "Usual" },
            { value: 2, label: "Facebook Allow" },
            { value: 3, label: "Click2Call" }, // always came from mobile
            { value: 4, label: "Engagement" },
            { value: 5, label: "CallBack" }, // like Click2Call but always came from web
            { value: 6, label: "Facebook Ads" }, // facebook ads leads
            { value: 7, label: "Overlay" }, // On Site
            { value: 8, label: "Incoming Call" }, // incoming call
            { value: 9, label: "Outgoing Call" }, // outgoing call
            { value: 10, label: "External Lead Api" }, // Leads sent to us from external website via API
            { value: 11, label: "YouTube" }, // incoming leads from YouTube and Google
            { value: 12, label: "Incoming WhatsApp" }, // incoming WhatsApp
            { value: 13, label: "Outgoing WhatsApp" }, // outgoing WhatsApp
            { value: 14, label: "Tiktok Ads" }, // tiktok ads leads
            { value: 15, label: "Statistic Lead" }, // leads that saved just for statistic
          ],
        },
        {
          id: "campaignSource",
          label: "Campaign Source",
          type: "checkboxGroup",
          required: true,
          options: [
            { value: "basic", label: "Campaign 1" },
            { value: "qualified", label: "Vampaign 2" },
            { value: "customer", label: "Campaign 3" },
          ],
        },
      ],
      nodeComponent: NewLeadNode,
    },
    {
      type: "addedToSegment",
      displayName: "Added to Segment",
      icon: AddIcon,
      iconColor: "#26a69a",
      formFields: [
        {
          id: "segment",
          label: "Choose Segment",
          required: true,
          type: "checkboxGroup",
          options: [],
        },
        {
          id: "useSharedExit",
          label: "Share Exit Node",
          type: "checkbox",
          defaultValue: false,
        },
      ],
      nodeComponent: AddedToSegment,
    },
    // ... other node types
  ],
  actions: [
    {
      type: "waitXTime",
      displayName: "Wait",
      icon: AccessTimeIcon,
      iconColor: "#78909c",
      formFields: [
        {
          id: "timeAmount",
          label: "Amount",
          type: "number",
          min: 1,
          required:true,
        },
        {
          id: "timeUnit",
          label: "Unit",
          type: "select",
          required:true,
          options: [
            { value: "minutes", label: "Minutes" },
            { value: "hours", label: "Hours" },
            { value: "days", label: "Days" },
          ],
        },
        {
          id: "daysToSkip",
          label: "Days to Skip",
          type: "checkboxGroup",
          options: [
            { value: "sunday", label: "Sunday" },
            { value: "monday", label: "Monday" },
            { value: "tuesday", label: "Tuesday" },
            { value: "wednesday", label: "Wednesday" },
            { value: "thursday", label: "Thursday" },
            { value: "friday", label: "Friday" },
            { value: "saturday", label: "Saturday" },
          ],
        },
        {
          id: "hoursToSkip",
          label: "Hours to Skip",
          type: "timeRange",
          defaultValue: ["09:00", "17:00"],
        },
      ],
      nodeComponent: WaitNode,
    },
    {
      type: "conditionNode",
      displayName: "Condition",
      icon: QuestionMarkIcon,
      iconColor: "#9575cd",
      formFields: [
        {
          id: "conditions",
          type: "conditionBuilder",
          conditionTypes: conditionTypes,
        },
      ],
      nodeComponent: ConditionNode,
    },
    {
      type: "whatsappMessage",
      displayName: "WhatsApp Message",
      icon: WhatsAppIcon,
      iconColor: "#25D366",
      formFields: [
        {
          id: "sendFrom",
          label: "Send From...",
          type: "select",
          options: [
            { value: "wa1", label: "Business Account 1" },
            { value: "wa2", label: "Business Account 2" },
            { value: "wa3", label: "Marketing Account" },
          ],
        },
        {
          id: "template",
          label: "Template",
          type: "select",
          options: [
            { value: "wat1", label: "Welcome Message" },
            { value: "wat2", label: "Order Confirmation" },
            { value: "wat3", label: "Appointment Reminder" },
            { value: "wat4", label: "Payment Receipt" },
          ],
        },
      ],
      nodeComponent: WhatsAppMessageNode,
    },
    {
      type: "emailMessage",
      displayName: "Email Message",
      icon: EmailIcon,
      iconColor: "#4fc3f7",
      formFields: [
        {
          id: "sendFrom",
          label: "Send From...",
          type: "select",
          options: [
            { value: "em1", label: "support@company.com" },
            { value: "em2", label: "sales@company.com" },
            { value: "em3", label: "marketing@company.com" },
            { value: "em4", label: "noreply@company.com" },
          ],
        },
        {
          id: "subject",
          label: "Subject",
          type: "text",
        },
        {
          id: "template",
          label: "Template",
          type: "select",
          options: [
            { value: "et1", label: "Welcome Email" },
            { value: "et2", label: "Monthly Newsletter" },
            { value: "et3", label: "Password Reset" },
            { value: "et4", label: "Order Confirmation" },
            { value: "et5", label: "Promotional Offer" },
          ],
        },
      ],
      nodeComponent: EmailMessageNode,
    },
    {
      type: "smsMessage",
      displayName: "SMS Message",
      icon: SmsIcon,
      iconColor: "#FF9800",
      formFields: [
        {
          id: "sendFrom",
          label: "Send From...",
          type: "select",
          options: [
            { value: "sms1", label: "COMPANY" },
            { value: "sms2", label: "SUPPORT" },
            { value: "sms3", label: "ALERTS" },
            { value: "sms4", label: "+1234567890" },
          ],
        },
        {
          id: "template",
          label: "Template",
          type: "select",
          options: [
            { value: "st1", label: "Verification Code" },
            { value: "st2", label: "Delivery Update" },
            { value: "st3", label: "Appointment Reminder" },
            { value: "st4", label: "Payment Confirmation" },
            { value: "st5", label: "Flash Sale Alert" },
          ],
        },
      ],
      nodeComponent: SMSMessageNode,
    },
  ],
  specialNodes: [
    {
      type: "exitNode",
      displayName: "Exit",
      nodeComponent: ExitNode,
    },
  ],
};
export default nodeTypesConfig;
