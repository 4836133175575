import React from 'react'
import ActionNodeTemplate from '../templates/ActionNodeTemplate'

export default function WhatsAppMessageNode({ id, type, data, selected }) {
    return (
        <ActionNodeTemplate id={id} type={type} data={data} selected={selected}>
            <div>Send From: {data.sendFrom.label}</div>
            <div>Template: {data.template.label}</div>
        </ActionNodeTemplate>
    )
} 