import React from 'react'
import ActionNodeTemplate from '../templates/ActionNodeTemplate'

export default function EmailMessageNode({ id, type, data, selected }) {
    console.log(data);
    
    return (
        <ActionNodeTemplate id={id} type={type} data={data} selected={selected}>
            <div>Send From: {data.sendFrom.label}</div>
            <div>Subject: {data.subject}</div>
            <div>Template: {data.template.label}</div>
        </ActionNodeTemplate>
    )
} 