import { useCallback } from "react";
import nodeTypesConfig from "../../models/nodeTypesConfig";

export default function useNodeManager(
  nodes,
  edges,
  setNodes,
  setEdges,
  generateId
) {
  const addNodeBetweenNodes = useCallback(
    (type, data = {}, edgeId) => {
      const edge = edges.find((e) => e.id === edgeId);
      if (!edge) {
        console.error(`Edge with id ${edgeId} not found.`);
        return;
      }

      const sourceNode = nodes.find((n) => n.id === edge.source);
      const targetNode = nodes.find((n) => n.id === edge.target);

      if (!sourceNode || !targetNode) {
        console.error(`Source or target node not found for edge ${edgeId}.`);
        return;
      }

      // Remove the old edge
      setEdges((eds) => eds.filter((e) => e.id !== edgeId));

      const newNodeId = generateId(type);

      // Determine if the parent node is a condition node
      const isParentConditionNode = sourceNode.type === "conditionNode";
      const parentEdgeType = isParentConditionNode
        ? "conditionEdge"
        : "actionEdge";
      const parentSourceHandle = isParentConditionNode
        ? edge.sourceHandle
        : undefined;

      // Edge from source node to new node
      const sourceToNewEdge = {
        id: generateId("edge"),
        source: sourceNode.id,
        target: newNodeId,
        type: parentEdgeType,
        sourceHandle: parentSourceHandle,
        data: edge.data || {},
      };

      // Determine if the new node is a condition node
      const isNewNodeConditionNode = type === "conditionNode";

      // Edge from new node to target node
      const newToTargetEdge = {
        id: generateId("edge"),
        source: newNodeId,
        target: targetNode.id,
        type: isNewNodeConditionNode ? "conditionEdge" : "actionEdge",
        sourceHandle: isNewNodeConditionNode ? "yes" : undefined,
        data: isNewNodeConditionNode ? { label: "Yes" } : {},
      };

      // Add the new edges
      setEdges((eds) => eds.concat([sourceToNewEdge, newToTargetEdge]));

      // Create the new node
      const newNode = {
        id: newNodeId,
        type,
        data: {
          label:
            data.label ||
            nodeTypesConfig.actions.find((n) => n.type === type)?.displayName ||
            "New Node",
          ...data,
        },
        position: { x: 0, y: 0 }, // Will be set after positioning
      };

      // If the new node is a condition node, create an exit node
      let exitNodeId = null;
      if (isNewNodeConditionNode) {
        exitNodeId = generateId("exitNode");
        const exitNode = {
          id: exitNodeId,
          type: "exitNode",
          data: { label: "Exit" },
          position: { x: 0, y: 0 }, // Will be set after positioning
        };

        // Edge from new node to exit node
        const exitEdge = {
          id: generateId("edge"),
          source: newNodeId,
          target: exitNodeId,
          sourceHandle: "no",
          type: "conditionEdge",
          data: { label: "No" },
        };

        setEdges((eds) => eds.concat(exitEdge));
        setNodes((nds) => nds.concat([newNode, exitNode]));
      } else {
        setNodes((nds) => nds.concat(newNode));
      }

      // Adjust positions after rendering and measuring
      const adjustPositions = () => {
        setNodes((currentNodes) => {
          const updatedNodes = [...currentNodes];

          const newNodeIndex = updatedNodes.findIndex(
            (n) => n.id === newNodeId
          );
          const exitNodeIndex = updatedNodes.findIndex(
            (n) => n.id === exitNodeId
          );
          const targetNodeIndex = updatedNodes.findIndex(
            (n) => n.id === targetNode.id
          );

          const newNodeData = updatedNodes[newNodeIndex];
          const exitNodeData = updatedNodes[exitNodeIndex];
          const targetNodeData = updatedNodes[targetNodeIndex];

          // Ensure that the parent node's width is available
          const parentNodeIndex = updatedNodes.findIndex(
            (n) => n.id === sourceNode.id
          );
          const parentNodeData = updatedNodes[parentNodeIndex];
          const parentNodeWidth =
            parentNodeData?.width || parentNodeData?.measured?.width;

          if (!parentNodeWidth) {
            // If the width is not available, retry after a delay
            setTimeout(adjustPositions, 50);
            return updatedNodes;
          }

          // Position the new node
          let xOffset = 0;
          if (isParentConditionNode) {
            if (edge.sourceHandle === "yes") {
              xOffset = -200; // Adjust as needed
            } else if (edge.sourceHandle === "no") {
              const extraSpacing = 50; // Adjust as needed
              xOffset = parentNodeWidth + extraSpacing;
            }
          }

          updatedNodes[newNodeIndex] = {
            ...newNodeData,
            position: {
              x: sourceNode.position.x + xOffset,
              y: sourceNode.position.y + 150,
            },
          };

          // Adjust the target node's position
          updatedNodes[targetNodeIndex] = {
            ...targetNodeData,
            position: {
              x: updatedNodes[newNodeIndex].position.x-100,
              y: updatedNodes[newNodeIndex].position.y + 150,
            },
          };

          // Adjust downstream nodes
          adjustDownstreamNodes(targetNodeData.id, updatedNodes, edges, 0, 150);

          // Position the exit node ("No" node) based on new node's width
          if (isNewNodeConditionNode && exitNodeId) {
            const newNodeWidth =
              newNodeData?.width || newNodeData?.measured?.width || 200;
            const extraSpacing = 50; // Adjust as needed

            updatedNodes[exitNodeIndex] = {
              ...exitNodeData,
              position: {
                x:
                  updatedNodes[newNodeIndex].position.x +
                  newNodeWidth +
                  extraSpacing,
                y: updatedNodes[newNodeIndex].position.y + 150,
              },
            };
          }

          return updatedNodes;
        });
      };

      // Start position adjustment after a delay
      setTimeout(adjustPositions, 50);
    },
    [nodes, edges, setNodes, setEdges, generateId]
  );

  const cleanupOrphanedExitNodes = useCallback(() => {
    setNodes((currentNodes) => {
      // Find all exit nodes
      const exitNodes = currentNodes.filter((node) => node.type === "exitNode");

      // Use the latest edges
      const updatedEdges = edges;

      // Identify exit nodes with no incoming edges
      const exitNodesToDelete = exitNodes.filter((exitNode) => {
        const hasIncomingEdge = updatedEdges.some(
          (edge) => edge.target === exitNode.id
        );
        return !hasIncomingEdge;
      });

      // Delete the orphaned exit nodes
      if (exitNodesToDelete.length > 0) {
        return currentNodes.filter(
          (node) =>
            !exitNodesToDelete.some((exitNode) => exitNode.id === node.id)
        );
      } else {
        return currentNodes;
      }
    });
  }, [edges, setNodes]);

  const onNodesDelete = useCallback(
    (deletedNodes) => {
      const deletedNodeIds = new Set(deletedNodes.map((node) => node.id));

      setEdges((currentEdges) => {
        // Remove edges connected to deleted nodes
        let newEdges = currentEdges.filter(
          (edge) =>
            !deletedNodeIds.has(edge.source) && !deletedNodeIds.has(edge.target)
        );

        deletedNodes.forEach((deletedNode) => {
          // Find incomers (edges where the deleted node is the target)
          const incomers = edges.filter(
            (edge) => edge.target === deletedNode.id
          );
          // Find outgoers (edges where the deleted node is the source)
          const outgoers = edges.filter(
            (edge) => edge.source === deletedNode.id
          );

          // Reconnect incomers to outgoers
          incomers.forEach((inEdge) => {
            outgoers.forEach((outEdge) => {
              newEdges.push({
                id: `${inEdge.source}->${outEdge.target}`,
                source: inEdge.source,
                target: outEdge.target,
                type: outEdge.type || "actionEdge",
                data: outEdge.data || {},
              });
            });
          });
        });

        return newEdges;
      });

      // Remove deleted nodes from the nodes state
      setNodes((currentNodes) =>
        currentNodes.filter((node) => !deletedNodeIds.has(node.id))
      );

      // After node deletions and reconnections, cleanup orphaned exit nodes
      setTimeout(() => {
        cleanupOrphanedExitNodes();
      }, 0);
    },
    [edges, setNodes, setEdges, cleanupOrphanedExitNodes]
  );

  const addNode = useCallback(
    (type, data = {}) => {
      if (type === "addedToSegment" && data.segment) {
        if (data.useSharedExit) {
          // Create a single shared exit node
          const exitNodeId = generateId("exitNode");
          const exitNode = {
            id: exitNodeId,
            type: "exitNode",
            data: { label: "Exit" },
            width: 50,
            position: {
              x: 250 + ((data.segment.length - 1) * 300) / 2,
              y: 250,
            },
          };

          // Create all segment nodes first
          const newNodes = data.segment.map((segment, index) => {
            const newNodeId = generateId(type);
            return {
              id: newNodeId,
              type,
              data: {
                label:
                  data.label ||
                  nodeTypesConfig.triggers.find((n) => n.type === type)
                    ?.displayName ||
                  "New Trigger",
                ...data,
                segment: [segment],
              },
              position: {
                x: 250 + index * 300,
                y: 50,
              },
            };
          });

          // Update nodes with shared exit
          setNodes((nds) => {
            const allNewNodes = [...newNodes, exitNode];

            setTimeout(() => {
              setNodes((currentNodes) => {
                const segmentNodes = newNodes
                  .map((node) => currentNodes.find((n) => n.id === node.id))
                  .filter(Boolean);

                if (segmentNodes.length && segmentNodes[0]?.measured?.width) {
                  const nodeWidth = segmentNodes[0].measured.width;
                  const totalWidth =
                    (data.segment.length - 1) * 300 + nodeWidth;
                  const centerX = 250 + totalWidth / 2;
                  const exitNodeWidth = 50;

                  return currentNodes.map((node) => {
                    if (node.id === exitNodeId) {
                      return {
                        ...node,
                        position: {
                          x: centerX - exitNodeWidth / 2,
                          y: 250,
                        },
                      };
                    }
                    return node;
                  });
                }
                return currentNodes;
              });
            }, 50);

            return nds.concat(allNewNodes);
          });

          // Create edges to shared exit
          setEdges((eds) => {
            const newEdges = newNodes.map((node) => ({
              id: generateId("edge"),
              source: node.id,
              target: exitNodeId,
              type: "actionEdge",
            }));
            return eds.concat(newEdges);
          });
        } else {
          // Create separate exit nodes for each segment
          data.segment.forEach((segment, index) => {
            const newNodeId = generateId(type);
            const exitNodeId = generateId("exitNode");
            const edgeId = generateId("edge");

            const nodePosition = {
              x: 250 + index * 300,
              y: 50,
            };

            const newNode = {
              id: newNodeId,
              type,
              data: {
                label:
                  data.label ||
                  nodeTypesConfig.triggers.find((n) => n.type === type)
                    ?.displayName ||
                  "New Trigger",
                ...data,
                segment: [segment],
              },
              position: nodePosition,
            };

            const exitNode = {
              id: exitNodeId,
              type: "exitNode",
              data: { label: "Exit" },
              width: 50,
              position: {
                x: nodePosition.x,
                y: nodePosition.y + 200,
              },
            };

            setNodes((nds) => {
              const newNodes = nds.concat([newNode, exitNode]);

              setTimeout(() => {
                setNodes((currentNodes) => {
                  const parentNode = currentNodes.find(
                    (n) => n.id === newNodeId
                  );
                  if (parentNode?.measured?.width) {
                    const parentCenter = Math.round(
                      nodePosition.x + parentNode.measured.width / 2
                    );
                    const exitNodeWidth = 50;

                    return currentNodes.map((node) => {
                      if (node.id === exitNodeId) {
                        return {
                          ...node,
                          position: {
                            x: parentCenter - exitNodeWidth / 2,
                            y: nodePosition.y + 200,
                          },
                        };
                      }
                      return node;
                    });
                  }
                  return currentNodes;
                });
              }, 50);

              return newNodes;
            });

            setEdges((eds) =>
              eds.concat({
                id: edgeId,
                source: newNodeId,
                target: exitNodeId,
                type: "actionEdge",
              })
            );
          });
        }
      } else {
        // Handle non-segment nodes (original logic)
        const newNodeId = generateId(type);
        const exitNodeId = generateId("exitNode");
        const edgeId = generateId("edge");

        const nodePosition = data.position || { x: 250, y: 50 };

        const newNode = {
          id: newNodeId,
          type,
          data: {
            label:
              data.label ||
              nodeTypesConfig.triggers.find((n) => n.type === type)
                ?.displayName ||
              "New Trigger",
            ...data,
          },
          position: nodePosition,
        };

        // Create the exit node with initial position
        const exitNode = {
          id: exitNodeId,
          type: "exitNode",
          data: { label: "Exit" },
          width: 50,
          position: {
            x: nodePosition.x,
            y: nodePosition.y + 200,
          },
        };

        // Update nodes first
        setNodes((nds) => {
          const newNodes = nds.concat([newNode, exitNode]);

          // Use setTimeout to ensure React Flow has time to measure the parent node
          setTimeout(() => {
            setNodes((currentNodes) => {
              const parentNode = currentNodes.find((n) => n.id === newNodeId);
              if (parentNode?.measured?.width) {
                // Round positions to avoid decimal issues
                const parentCenter = Math.round(
                  nodePosition.x + parentNode.measured.width / 2
                );
                const exitNodeWidth = 50;

                return currentNodes.map((node) => {
                  if (node.id === exitNodeId) {
                    return {
                      ...node,
                      position: {
                        x: parentCenter - exitNodeWidth / 2,
                        y: nodePosition.y + 200,
                      },
                    };
                  }
                  return node;
                });
              }
              return currentNodes;
            });
          }, 50);

          return newNodes;
        });

        // Create the edge between trigger node and exit node
        setEdges((eds) =>
          eds.concat({
            id: edgeId,
            source: newNodeId,
            target: exitNodeId,
            type: "actionEdge",
          })
        );
      }
    },
    [setNodes, setEdges, generateId]
  );

  const editNode = useCallback(
    (nodeId, newData) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            // Create new node object to ensure React Flow detects the change
            return {
              ...node,
              data: {
                ...node.data,
                ...newData,
              },
            };
          }
          return node;
        })
      );
    },
    [setNodes]
  );

  return {
    addNodeBetweenNodes,
    addNode,
    onNodesDelete,
    editNode,
    cleanupOrphanedExitNodes,
  };
}

const adjustDownstreamNodes = (
  nodeId,
  nodesArray,
  edgesArray,
  deltaX = 0,
  deltaY = 150
) => {
  const nodeIndex = nodesArray.findIndex((n) => n.id === nodeId);
  if (nodeIndex === -1) return;

  const node = nodesArray[nodeIndex];

  const downstreamEdges = edgesArray.filter((e) => e.source === node.id);
  downstreamEdges.forEach((edge) => {
    const childNodeIndex = nodesArray.findIndex((n) => n.id === edge.target);
    if (childNodeIndex !== -1) {
      const childNode = nodesArray[childNodeIndex];

      // Adjust position
      nodesArray[childNodeIndex] = {
        ...childNode,
        position: {
          x: childNode.position.x + deltaX,
          y: node.position.y + deltaY,
        },
      };

      // Recursively adjust downstream nodes
      adjustDownstreamNodes(
        childNode.id,
        nodesArray,
        edgesArray,
        deltaX,
        deltaY
      );
    }
  });
};

const getAllDescendants = (nodeId, nodes, edges) => {
  const descendants = [];
  // Start with the initial nodeId we want to check
  let nodesToCheck = [nodeId];

  // Keep going until we've checked all connected nodes
  while (nodesToCheck.length > 0) {
    // Take the next node to check
    const currentId = nodesToCheck.pop();
    // Find all edges where this node is the source
    const outgoingEdges = edges.filter((edge) => edge.source === currentId);

    // For each outgoing edge, find where it connects to
    for (const edge of outgoingEdges) {
      const targetNode = nodes.find((node) => node.id === edge.target);
      // If we found a target node and haven't already included it
      if (targetNode && !descendants.some((n) => n.id === targetNode.id)) {
        descendants.push(targetNode);
        nodesToCheck.push(targetNode.id);
      }
    }
  }

  return descendants;
};
