import nodeTypesConfig from "../../models/nodeTypesConfig";

export default function getNodeTypes() {
  return [...nodeTypesConfig.triggers, ...nodeTypesConfig.actions, ...nodeTypesConfig.specialNodes].reduce(
    (acc, node) => {
      acc[node.type] = node.nodeComponent;
      return acc;
    },
    {}
  );
}
