import React, { useState } from 'react';
import {
    Box,
    Select,
    MenuItem,
    IconButton,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Typography,
    Grid,
    FormControlLabel,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ConditionRow = ({ condition, onUpdate, onDelete, conditionTypes }) => {
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>Condition Type</InputLabel>
                    <Select
                        value={condition.type}
                        onChange={(e) => onUpdate({ ...condition, type: e.target.value, value: '' })}
                        label="Condition Type"
                    >
                        {Object.entries(conditionTypes).map(([key, type]) => (
                            <MenuItem key={key} value={key}>{type.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <InputLabel>Value</InputLabel>
                    <Select
                        value={condition.value}
                        onChange={(e) => onUpdate({ ...condition, value: e.target.value })}
                        label="Value"
                    >
                        {condition.type && conditionTypes[condition.type].options.map(option => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={condition.isWhitelist}
                            onChange={(e) => onUpdate({ ...condition, isWhitelist: e.target.checked })}
                        />
                    }
                    label={condition.isWhitelist ? "Include" : "Exclude"}
                />
            </Grid>
            <Grid item xs={2}>
                <IconButton onClick={onDelete} color="error">
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

const ConditionBuilder = ({ value, onChange, conditionTypes }) => {
    const [conditions, setConditions] = useState(value || []);
    const [globalMode, setGlobalMode] = useState('whitelist');

    const handleAddCondition = (operator) => {
        const newCondition = {
            type: '',
            value: '',
            operator,
            isWhitelist: globalMode === 'whitelist',
        };

        setConditions([...conditions, newCondition]);
        onChange([...conditions, newCondition]);
    };

    const handleUpdateCondition = (index, updatedCondition) => {
        const newConditions = [...conditions];
        newConditions[index] = updatedCondition;
        setConditions(newConditions);
        onChange(newConditions);
    };

    const handleDeleteCondition = (index) => {
        const newConditions = conditions.filter((_, i) => i !== index);
        setConditions(newConditions);
        onChange(newConditions);
    };

    const handleToggleAll = () => {
        const newMode = globalMode === 'whitelist' ? 'blacklist' : 'whitelist';
        setGlobalMode(newMode);

        const updatedConditions = conditions.map((condition) => ({
            ...condition,
            isWhitelist: newMode === 'whitelist',
        }));

        setConditions(updatedConditions);
        onChange(updatedConditions);
    };

    return (
        <Box sx={{ width: '100%', p: 2 }}>
            {/* Toggle Button for Whitelist/Blacklist */}
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                <ToggleButtonGroup
                    value={globalMode}
                    exclusive
                    onChange={handleToggleAll}
                    aria-label="Toggle Whitelist/Blacklist"
                >
                    <ToggleButton value="whitelist">Include All</ToggleButton>
                    <ToggleButton value="blacklist">Exclude All</ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Stack spacing={2}>
                {conditions.map((condition, index) => (
                    <Box key={index}>
                        {index > 0 && (
                            <Typography variant="body2" color="textSecondary" align="center" sx={{ my: 1 }}>
                                {condition.operator}
                            </Typography>
                        )}
                        <ConditionRow
                            condition={condition}
                            onUpdate={(updated) => handleUpdateCondition(index, updated)}
                            onDelete={() => handleDeleteCondition(index)}
                            conditionTypes={conditionTypes}
                        />
                    </Box>
                ))}

                {(conditions.length === 0 || conditions.length > 0) && (
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <Button
                            variant="outlined"
                            onClick={() => handleAddCondition('AND')}
                        >
                            Add AND Condition
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => handleAddCondition('OR')}
                        >
                            Add OR Condition
                        </Button>
                    </Stack>
                )}
            </Stack>
        </Box>
    );
};

export default ConditionBuilder;
