// components/flow/nodes/StayedTimeOnPageNode.js
import { Handle } from '@xyflow/react';
import React from 'react';
import TriggerNodeTemplate from '../templates/TriggerNodeTemplate';

const StayedTimeOnPageNode = ({ id, type, data }) => {
    return (
        <TriggerNodeTemplate id={id} type={type} data={data}>
            <div>Time: {data.timeAmount} {data.timeUnit.label}</div>
            <div>Send To: {data.sendTo}</div>
            <div>
                Days to Skip: {data.daysToSkip && data.daysToSkip.map(day => day.label).join(', ')}
            </div>
            <Handle type="source" position="bottom" style={{ background: '#555' }} />
        </TriggerNodeTemplate>
    );
};

export default StayedTimeOnPageNode;
