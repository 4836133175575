// useAutomationAPI.js
import { checkError } from "../../utils/automations/checkError";
import { transformFormToApiPayload } from "../../utils/transformers/automationTransformers";
import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const createHeaders = (brandId) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${apiKey}`);
  if (brandId) {
    myHeaders.append("brandId", brandId);
  }
  return myHeaders;
};

// useAutomationAPI.js

const fetchAPI = async (endpoint, options = {}) => {
  const requestOptions = {
    method: options.method || "GET",
    redirect: "follow",
    ...options,
    headers: createHeaders(options.brandId),
  };

  if (requestOptions.body) {
    requestOptions.headers.append("Content-Type", "application/json");
  }

  const response = await fetch(`${baseURL}${endpoint}`, requestOptions);

  const responseText = await response.text();

  let responseData = null;

  if (responseText) {
    try {
      responseData = JSON.parse(responseText);
    } catch (error) {
      console.error("Failed to parse JSON response:", error);
      // Optionally, you can throw an error or set responseData to an empty object
      // responseData = {};
    }
  }

  if (!response.ok) {
    const error = responseData || { message: "Unknown error occurred" };
    checkError(error);
    throw error;
  }

  return responseData;
};

export const getUserData = async () => {
  try {   
    return await fetchAPI("/V1/Journey/GetUserData");
  } catch (error) {
    checkError(error);
    throw error;
  }
};

export const getBrandSegments = async (brandId) => {
  try {
    return await fetchAPI("/V1/Journey/GetBrandSegments", {
      brandId: parseInt(brandId),
    });
  } catch (error) {
    checkError(error);
    throw error;
  }
};

export const createJourneyAutomation = async (payload, brandId, userId) => {
  try {
    const apiPayload = transformFormToApiPayload(payload, brandId, userId);
    console.log(apiPayload);
    const response = await fetchAPI("/V1/Journey/CreateJourneyAutomation", {
      method: "POST",
      body: JSON.stringify(apiPayload),
      brandId: parseInt(brandId),
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const editJourneyAutomation = async (payload) => {
  try {
    return await fetchAPI("/V1/Journey/EditJourneyAutomation", {
      method: "PATCH",
      body: JSON.stringify(payload),
      brandId: payload.BrandId,
    });
  } catch (error) {
    checkError(error);
    throw error;
  }
};

export const saveJourneyAutomation = async (payload) => {
  try {
    return await fetchAPI("/V1/Journey/SaveJourneyAutomation", {
      method: "PATCH",
      body: JSON.stringify(payload),
    });
  } catch (error) {
    checkError(error);
    throw error;
  }
};

export const addJourneyActions = async (payload) => {
  try {
    return await fetchAPI("/V1/Journey/AddJourneyActions", {
      method: "POST",
      body: JSON.stringify(payload),
    });
  } catch (error) {
    checkError(error);
    throw error;
  }
};

export const deleteJourneyActions = async (actionId) => {
  try {
    return await fetchAPI(`/V1/Journey/DeleteJourneyActions/${actionId}`, {
      method: "DELETE",
    });
  } catch (error) {
    checkError(error);
    throw error;
  }
};

export const deleteJourneyAutomation = async (automationId) => {
  try {
    return await fetchAPI(
      `/V1/Journey/DeleteJourneyAutomation/${automationId}`,
      {
        method: "DELETE",
      }
    );
  } catch (error) {
    checkError(error);
    throw error;
  }
};

export const getJourneyAutomations = async (brandId) => {
  try {
    return await fetchAPI("/V1/Journey/GetJourneyAutomations", { brandId });
  } catch (error) {
    checkError(error);
    throw error;
  }
};

export const getJourneyAutomation = async (automationId) => {
  const response = await axios({
    method: "get",
    url: `${baseURL}/V1/Journey/GetJourneyAutomation`,
    headers: {
      Authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json",
      brandId: localStorage.getItem("brandId"),
    },
    data: {
      Id: automationId,
    },
  });

  return response.data;
};
