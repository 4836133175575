// components/AutomationTable.jsx
import React, { useState } from 'react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    TableSortLabel,
} from '@mui/material';
import AutomationRow from './AutomationRow';

export default function AutomationTable({ automations }) {
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Name');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedRows = React.useMemo(() => {
        return [...automations].sort((a, b) => {
            let aValue, bValue;

            // Handle nested properties
            if (orderBy.includes('.')) {
                const [parent, child] = orderBy.split('.');
                aValue = a[parent][child];
                bValue = b[parent][child];
            } else {
                aValue = a[orderBy];
                bValue = b[orderBy];
            }

            // Handle date fields
            if (
                orderBy === 'Schedule.start' ||
                orderBy === 'Schedule.end' ||
                orderBy === 'Schedule.nextRun'
            ) {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
            }

            // Handle string comparison
            if (typeof aValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }

            if (aValue < bValue) {
                return order === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }, [automations, order, orderBy]);

    return (
        <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ width: '85vw', margin: 'auto' }}
        >
            <Table aria-label="automation table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'Name'}
                                direction={orderBy === 'Name' ? order : 'asc'}
                                onClick={() => handleRequestSort('Name')}
                            >Name</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'Status'}
                                direction={orderBy === 'Status' ? order : 'asc'}
                                onClick={() => handleRequestSort('Status')}
                            >Status</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'Priority'}
                                direction={orderBy === 'Priority' ? order : 'asc'}
                                onClick={() => handleRequestSort('Priority')}
                            >Priority</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'Schedule.Start'}
                                direction={orderBy === 'Schedule.Start' ? order : 'asc'}
                                onClick={() => handleRequestSort('Schedule.Start')}
                            >Start Date</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'Schedule.End'}
                                direction={orderBy === 'Schedule.End' ? order : 'asc'}
                                onClick={() => handleRequestSort('Schedule.End')}
                            >End Date</TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'Schedule.NextRun'}
                                direction={orderBy === 'Schedule.NextRun' ? order : 'asc'}
                                onClick={() => handleRequestSort('Schedule.NextRun')}
                            >Next Run</TableSortLabel>
                        </TableCell>
                        <TableCell>Audiences</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRows.length > 0 ? (
                        sortedRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <AutomationRow
                                    key={row.id}
                                    row={row}
                                />
                            ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={9} align="center">
                                No automations available.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={automations.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
            />
        </TableContainer>
    );
}
