import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import CreateAutomationForm from '../forms/CreateAutomationForm';

export default function EditAutomationDialog({
    open,
    onClose,
    onSubmit,
    initialAutomationData,
}) {
    const [automationData, setAutomationData] = useState(initialAutomationData);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            {automationData && (
                <>
                    <DialogTitle>Edit Automation</DialogTitle>
                    <DialogContent>
                        <CreateAutomationForm
                            automationData={automationData}
                            setAutomationData={setAutomationData}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={() => onSubmit(automationData)} variant="contained">
                            Save
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
