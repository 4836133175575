import React, { useState } from 'react';
import {
    TableRow,
    TableCell,
    IconButton,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, MoreVert } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AutomationDetails from './AutomationDetails';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { deleteAutomation } from '../../hooks/automationhooks/useTestAutomationAPI';
import useAutomations from '../../hooks/automationhooks/useAutomations';
import EditAutomationDialog from '../../forms/EditAutomationDialog';

export default function AutomationRow({ row }) {
    const { handleEditAutomation } = useAutomations();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const navigate = useNavigate();

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleWorkflowClick = () => {
        const brandId = row.brandId;
        navigate(`/workflow/${brandId}/${row.id}`);
        handleMenuClose();
    };

    const handleEditClick = () => {
        setIsEditDialogOpen(true);
        handleMenuClose();
    };

    const handleEditDialogClose = () => {
        setIsEditDialogOpen(false);
    };

    const onSubmit = (updatedAutomationData) => {
        handleEditAutomation(row.id, updatedAutomationData)
        setIsEditDialogOpen(false);
        window.location.reload();
    };

    const handleDeleteClick = (id) => {
        handleMenuClose();
        deleteAutomation(id);
        window.location.reload();
    };

    // Transform row data for AutomationDetails
    const detailsData = {
        name: row.name,
        description: row.description,
        priority: row.priority,
        scheduleStart: new Date(row.schedule.start).toLocaleString(),
        scheduleEnd: new Date(row.schedule.end).toLocaleString(),
        actions: row.Actions ? JSON.stringify(row.Actions) : 'No actions configured',
        metrics: `Executions: ${row.metrics.executions || 0}, Successes: ${row.metrics.successes || 0}, Failures: ${row.metrics.failures || 0}`,
        settings: row.settings ? "Retries: " + JSON.stringify(row.settings.retries) + ", Delay: " + JSON.stringify(row.settings.timeout) : 'Default settings',
        audiences: row.audiences ? (
            typeof row.audiences === 'string' ? row.audiences : JSON.stringify(row.audiences)
        ) : 'No audiences specified'
    };

    return (
        <>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                }}
                onClick={() => setOpen(!open)}
            >
                <TableCell>
                    <IconButton size="small">
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{row.name}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.priority}</TableCell>
                <TableCell>{new Date(row.schedule.start).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(row.schedule.end).toLocaleDateString()}</TableCell>
                <TableCell>{row.ExecutionsNum || '0'}</TableCell>
                <TableCell>{row.SuccessesNum || '0'}</TableCell>
                <TableCell>{row.FailuresNum || '0'}</TableCell>
                <TableCell align="right" onClick={(e) => e.stopPropagation()}>
                    <IconButton onClick={handleMenuClick}>
                        <MoreVert />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleWorkflowClick}>Workflow</MenuItem>
                        <MenuItem onClick={()=>handleEditClick()}>Edit</MenuItem>
                        <MenuItem onClick={() => handleDeleteClick(row.id)}>Delete</MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
            <AutomationDetails open={open} details={detailsData} />
            <EditAutomationDialog
                open={isEditDialogOpen}
                onClose={handleEditDialogClose}
                onSubmit={onSubmit}
                initialAutomationData={row}
            />
        </>
    );
}

AutomationRow.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        status: PropTypes.string,
        priority: PropTypes.string,
        schedule: PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string
        }),
        executionsNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        successesNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        failuresNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        audiences: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.object)
        ]),
        settings: PropTypes.object,
    }).isRequired,
};
