// components/flow/nodes/ExitNode.js
import React from 'react';
import CustomHandle from '../handles/CustomHandle';

const ExitNode = () => {
    return (
        <div className="node exit-node">
            <div style={{ textAlign: 'center' }}>
                <strong>Exit</strong>
            </div>
            <CustomHandle type="target" position="top" />
        </div>
    );
};

export default ExitNode;
