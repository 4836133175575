// utils/nodeUtils.js

import nodeTypesConfig from "../../models/nodeTypesConfig";

// Sets for quick lookup
export const triggerTypes = new Set(
  nodeTypesConfig.triggers.map((trigger) => trigger.type)
);
export const actionTypes = new Set(
  nodeTypesConfig.actions.map((action) => action.type)
);

export const isTriggerNode = (nodeType) => triggerTypes.has(nodeType);
export const isActionNode = (nodeType) => actionTypes.has(nodeType);

export const addNode = (type, nodes, data = {}) => {
  const nodeTypeConfig = findNodeTypeConfig(type);

  if (!nodeTypeConfig) {
    throw new Error(`Unknown node type: ${type}`);
  }

  const newNode = {
    id: `${type}-${Date.now()}`,
    type: nodeTypeConfig.type, // The node's type is used for mapping to the component
    position: {
      x: 250 + nodes.length * 50,
      y: 5 + nodes.length * 100,
    },
    data: {
      label: data.name || nodeTypeConfig.displayName,
      ...data,
    },
    // Add source or target position if needed
    ...(nodeTypeConfig.isSource
      ? { sourcePosition: "right" }
      : { targetPosition: "left" }),
  };

  return newNode;
};

export const findNodeTypeConfig = (type) => {
  const { triggers, actions, specialNodes } = nodeTypesConfig;
  return (
    triggers.find((node) => node.type === type) ||
    actions.find((node) => node.type === type) ||
    specialNodes.find((node) => node.type === type)
  );
};
