import React, { useState, useContext, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid2,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NodeConfigModal from './menus/NodeConfigModal';
import NodesDynamicForm from "../../forms/NodesDynamicForm"
import AddNodeContext from '../../contexts/AddNodeContext';
import nodeTypesConfig from "../../models/nodeTypesConfig"
import useAutomations from '../../hooks/automationhooks/useAutomations';
import { useParams } from 'react-router-dom';

const AddActionButton = ({ edgeId }) => {
    const [optionsModalOpen, setOptionsModalOpen] = useState(false);
    const { whatsappNumbers, emails, handleGetWhatsappNumbers, handleGetEmails } = useAutomations()
    const { automationId, brandId } = useParams();

    const [configModal, setConfigModal] = useState({
        open: false,
        content: null,
    });
    const { addNodeBetweenNodes } = useContext(AddNodeContext);

    const buttonColor = '#1976d2';  // MUI's default primary blue
    const buttonLightColor = '#e3f2fd';  // Very light blue, almost white

    useEffect(() => {
        const currentBrandId = brandId || localStorage.getItem('brandId');
        if (currentBrandId) {
            handleGetWhatsappNumbers(currentBrandId);
        }
    }, [brandId]);
    useEffect(() => {
        const currentBrandId = brandId || localStorage.getItem('brandId');
        if (currentBrandId) {
            handleGetEmails(currentBrandId);
        }
    }, [brandId]);

    const handleOptionsButtonClick = (event) => {
        event.stopPropagation(); // Prevent edge click events
        setOptionsModalOpen(true);
    };

    const handleOptionsModalClose = () => {
        setOptionsModalOpen(false);
    };

    const handleConfigModalClose = () => {
        setConfigModal({ open: false, content: null });
    };
    console.log(emails);
    
    const handleOptionSelect = (type) => {
        setOptionsModalOpen(false);
        const nodeTypeConfig = nodeTypesConfig.actions.find(
            (node) => node.type === type
        );

        if (!nodeTypeConfig) {
            console.error(`Unknown node type: ${type}`);
            return;
        }


        //might want to make this be a general function that loads everything on workflow initialization, then passes down to children
        if (nodeTypeConfig.formFields && nodeTypeConfig.formFields.length > 0) {
            const formFields = nodeTypeConfig.formFields.map(field => {
                if (type === 'whatsappMessage') {
                    if (field.id === 'sendFrom') {   //not making these the same if statement cause we will need to check templates too, more effient question mark?       
                        return {
                            ...field,
                            options: whatsappNumbers.data.map(number => ({
                                value: number,
                                label: number
                            }))
                        };
                    }
                }
                if (type === 'emailMessage') {
                    if (field.id === 'sendFrom') {   //not making these the same if statement cause we will need to check templates too, more effient question mark?       
                        return {
                            ...field,
                            options: emails.data.map(number => ({
                                value: number,
                                label: number
                            }))
                        };
                    }
                }
                return field;
            });

            setConfigModal({
                open: true,
                content: (
                    <NodesDynamicForm
                        title={`Create "${nodeTypeConfig.displayName}" Node`}
                        formFields={formFields}
                        onSubmit={(formData) => {
                            addNodeBetweenNodes(type, formData, edgeId);
                            handleConfigModalClose();
                        }}
                        setConfigModal={setConfigModal}
                        handleCancel={() => setConfigModal({ open: false })}
                    />
                ),
            });

        } else {
            // If no form is required
            addNodeBetweenNodes(type, {}, edgeId);
        }
    };

    return (
        <>
            <Button
                onClick={handleOptionsButtonClick}
                variant="outlined"
                size="small"
                style={{
                    minWidth: '35px',
                    minHeight: '35px',
                }}
                sx={{
                    backgroundColor: buttonLightColor,  // Start with dark blue background
                    border: `2px dotted ${buttonColor}`,  // Start with light blue border
                    transition: 'all 0.2s ease',
                    '& .MuiSvgIcon-root': {
                        color: buttonColor,  // Start with light blue icon
                        transition: 'color 0.2s ease',
                    },
                    '&:hover': {
                        backgroundColor: buttonColor,  // Hover with light blue background
                        border: `2px dotted ${buttonLightColor}`,  // Hover with dark blue border
                        '& .MuiSvgIcon-root': {
                            color: buttonLightColor,  // Icon turns dark blue on hover
                        }
                    }
                }}
            >
                <AddIcon
                    fontSize="small"
                />
            </Button>

            {/* Options Modal */}
            <Dialog
                open={optionsModalOpen}
                onClose={handleOptionsModalClose}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        minWidth: '600px',
                    }
                }}
            >
                <DialogTitle sx={{ textAlign: 'center' }}>Select an Action Node</DialogTitle>
                <DialogContent
                    sx={{
                        p: 3,
                        pt: '24px !important',  // Override MUI's default padding-top
                        pb: '24px !important',  // Override MUI's default padding-bottom
                        overflow: 'hidden',     // Prevent scrollbars during hover
                    }}
                >
                    <Grid2
                        container
                        spacing={3}
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: '100%',
                            m: 0,
                            p: '12px',
                        }}
                    >
                        {nodeTypesConfig.actions.map((node) => (
                            <Grid2
                                item
                                xs={12}
                                sm={4}
                                key={node.type}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => handleOptionSelect(node.type)}
                                    sx={{
                                        width: '180px',
                                        height: '180px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        p: 2,
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                        }
                                    }}
                                >
                                    <node.icon sx={{ fontSize: '40px', mb: 2 }} />
                                    <div>{node.displayName}</div>
                                </Button>
                            </Grid2>
                        ))}
                    </Grid2>
                </DialogContent>
                <DialogActions sx={{ p: 3, justifyContent: 'flex-end' }}>
                    <Button
                        variant="outlined"
                        onClick={handleOptionsModalClose}
                        sx={{ minWidth: 100 }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Configuration Modal */}
            {configModal.open && (
                <NodeConfigModal open={configModal.open} onClose={handleConfigModalClose}>
                    {configModal.content}
                </NodeConfigModal>
            )}
        </>
    );
};

export default AddActionButton;
