import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import ROUTES from './Routes'
import JourneyList from '../pages/JourneyList'
import Workflow from '../pages/Workflow'

export default function () {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/journeys" replace />} />
                <Route path={ROUTES.ROOT} element={<JourneyList />} />
                <Route path={ROUTES.WORKFLOW} element={<Workflow />} />
                <Route path="*" element={<Navigate to="/journeys" replace />} />
            </Routes>
        </BrowserRouter>
    )
}
