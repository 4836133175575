import React from 'react'
import TriggerNodeTemplate from '../templates/TriggerNodeTemplate'

export default function AddedToSegment({ id, type, data, selected }) {
  return (
    <TriggerNodeTemplate id={id} type={type} data={data} selected={selected}>
      <div>
        Segments: {data.segment ?
          data.segment.map(segment => segment.label).join(', ') :
          'No segments selected'}
      </div>
    </TriggerNodeTemplate>
  )
}
