import React from 'react';
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from '@xyflow/react';
import AddActionButton from '../AddActionButton';

const ConditionEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    data,
}) => {
    // Generate the edge path
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
    });

    // Calculate the coordinates for each segment of the ConditionEdge
    const middleX = (sourceX + targetX) / 2;

    // First vertical segment coordinates for label
    const labelPosX = sourceX;
    const labelPosY = (sourceY + targetY) / 2; // Place it in the middle of the first vertical segment

    // Second vertical segment coordinates for button
    const buttonPosX = targetX;
    const buttonPosY = (sourceY + targetY) / 2; // Place it in the middle of the second vertical segment

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                {/* Label Positioned on the First Vertical Segment */}
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelPosX}px,${labelPosY}px)`,
                        pointerEvents: 'all',
                        backgroundColor: data?.label === 'Yes' ? '#4caf50' : '#f44336',
                        color: 'white',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '12px',
                        minWidth: '30px',
                        textAlign: 'center',
                        opacity: data?.label ? 1 : 0,
                        fontWeight: '500',
                    }}
                    className="nodrag nopan"
                >
                    {data?.label || ''}
                </div>
                {/* Button Positioned on the Second Vertical Segment */}
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${buttonPosX}px,${buttonPosY}px)`,
                        pointerEvents: 'all',
                    }}
                >
                    <AddActionButton edgeId={id} />
                </div>
            </EdgeLabelRenderer>
        </>
    );
};

export default ConditionEdge;
