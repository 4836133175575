// JourneyList.js
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import AutomationTable from '../components/table/AutomationTable';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CreateAutomationDialog from '../forms/CreateAutomationDialog';
import useAutomations from '../hooks/automationhooks/useAutomations';

export default function JourneyList() {
    const { brandId } = useParams();
    const navigate = useNavigate();
    const {
        automations,
        isLoading,
        userData,
        error,
        handleGetUserData,
        handleGetJourneyAutomations,
        handleCreateAutomationSubmit
    } = useAutomations();
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

    const [newAutomationData, setNewAutomationData] = useState({
        status: '',
        name: '',
        description: '',
        priority: '',
        startDate: '',
        endDate: '',
        audiences: []
    });

    const handleDialogClose = () => {
        setIsCreateDialogOpen(false);
        setNewAutomationData({
            status: '',
            name: '',
            description: '',
            priority: '',
            startDate: '',
            endDate: '',
            audiences: []
        });
    };

    const onCreateSuccess = (createdAutomation) => {
        console.log(createdAutomation);

        handleDialogClose();
        navigate(`/workflow/${brandId}/${createdAutomation.AutomationId}`);
    };

    // Single useEffect to handle initial load and redirect
    useEffect(() => {
        const loadInitialData = async () => {
            try {
                const loadedUserData = await handleGetUserData();
                // If we have brands and we're on the base journey page, redirect to first brand
                if (loadedUserData?.Brands?.length > 0 && !brandId) {
                    console.log("test")
                    navigate(`/journeys/${loadedUserData.Brands[0].BrandId}`);
                }
            } catch {
                console.log(error);
            }
        };

        loadInitialData();
    }, []); // Empty dependency array - runs once on mount

    // Separate useEffect for loading journey data once we have a brandId
    useEffect(() => {
        if (brandId) {
            handleGetJourneyAutomations(brandId);
        }
    }, [brandId]);

    return (
        <>
            {isLoading || !userData ? <div>Loading...</div> : (
                <>
                    <Header
                        brands={userData.Brands}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '98vw' }}>
                        <Typography variant="h4" sx={{ margin: '2vw', fontWeight: 'bold' }}>
                            Automations
                        </Typography>
                        <Button
                            variant="text"
                            sx={{ marginLeft: 'auto', fontWeight: 'bold', textTransform: 'none' }}
                            onClick={() => setIsCreateDialogOpen(true)}
                        >
                            +Create Automation
                        </Button>
                    </Box>
                    <AutomationTable automations={automations} />

                    <CreateAutomationDialog
                        open={isCreateDialogOpen}
                        onClose={handleDialogClose}
                        onSubmit={() => handleCreateAutomationSubmit(newAutomationData, brandId, onCreateSuccess)}
                        automationData={newAutomationData}
                        setAutomationData={setNewAutomationData}
                    />
                </>
            )}
        </>
    );
}
