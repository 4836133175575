// components/flow/nodes/ActionNodeTemplate.js
import React, { useState } from 'react';
import { NodeToolbar } from '@xyflow/react';
import PropTypes from 'prop-types';
import CustomHandle from '../../handles/CustomHandle';
import { IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NodesDynamicForm from '../../../../forms/NodesDynamicForm';
import nodeTypesConfig from '../../../../models/nodeTypesConfig';
import { useContext } from 'react';
import AddNodeContext from '../../../../contexts/AddNodeContext';
import NodeConfigModal from '../../menus/NodeConfigModal';

const ActionNodeTemplate = ({ id, type, data, selected, children }) => {
    const [configModal, setConfigModal] = useState({ open: false });
    const { editNode } = useContext(AddNodeContext);

    const handleEdit = () => {
        const nodeConfig = nodeTypesConfig.actions.find(
            (node) => node.type === type
        );

        setConfigModal({
            open: true,
            content: (
                <NodesDynamicForm
                    title={`Edit "${nodeConfig?.displayName}" Node`}
                    formFields={nodeConfig?.formFields || []}
                    initialData={data}
                    onSubmit={(formData) => {
                        editNode(id, formData);
                        setConfigModal({ open: false });
                    }}
                />
            ),
        });
    };

    // Get the icon component from config
    const nodeConfig = nodeTypesConfig.actions.find(node => node.type === type);
    const IconComponent = nodeConfig?.icon;
    const iconColor = nodeConfig?.iconColor || '#e0e0e0'; // Default gray if no color specified

    return (
        <>
            <NodeToolbar
                isVisible={selected}
                position="top"
                offset={8}
            >
                <IconButton
                    onClick={handleEdit}
                    size="small"
                    sx={{
                        bgcolor: 'background.paper',
                        '&:hover': { bgcolor: 'action.hover' },
                        padding: '4px',
                    }}
                >
                    <EditIcon sx={{ fontSize: 16 }} />
                </IconButton>
            </NodeToolbar>

            <div style={{
                padding: 6,
                border: '1px solid #777',
                borderRadius: 3,
                backgroundColor: '#e3f2fd',
                minWidth: 150,
                zIndex: 10,
                display: 'flex',
                alignItems: 'center',
                gap: '6px'
            }}>
                <CustomHandle type="target" position="top" />
                {IconComponent && (
                    <Box
                        sx={{
                            backgroundColor: iconColor,
                            borderRadius: 1,
                            padding: 0.4,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexShrink: 0,
                            color: 'white',
                            transition: 'background-color 0.2s'
                        }}
                    >
                        <IconComponent sx={{ fontSize: 14 }} />
                    </Box>
                )}
                <div style={{ fontSize: '0.5rem' }}>
                    <strong>{data.label}</strong>
                    {children}
                </div>
                <CustomHandle type="source" position="bottom" connectionCount={1} />
            </div>

            {configModal.open && (
                <NodeConfigModal
                    open={configModal.open}
                    onClose={() => setConfigModal({ open: false })}
                >
                    {configModal.content}
                </NodeConfigModal>
            )}
        </>
    );
};

ActionNodeTemplate.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    children: PropTypes.node,
};

export default ActionNodeTemplate;
