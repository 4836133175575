import { useState } from "react";
import { addJourneyActions, deleteJourneyActions } from "./useAutomationAPI";
import {
  createAutomation,
  updateAutomation,
  deleteAutomation,
  getFlowByAutomationId,
  createFlow,
  updateFlow,
  getAutomationsBybrandId,
  getBrandSegments,
  getUserData,
  getWhatsappNumbers,
  getCampaigns,
  getEmailSources,
} from "./useTestAutomationAPI";

export default function useAutomations() {
  const [userData, setUserData] = useState(null);
  const [brandSegments, setBrandSegments] = useState([]);
  const [whatsappNumbers, setWhatsappNumbers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [automations, setAutomations] = useState([]);
  const [currentAutomation, setCurrentAutomation] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleGetUserData = async () => {
    try {
      const response = await getUserData();
      setUserData(response);
      setLoading(false);
      return response;
    } catch (error) {
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  const handleGetBrandSegments = async (brandId) => {
    try {
      const response = await getBrandSegments(brandId);
      setBrandSegments(response);
      return response;
    } catch (error) {
      setError(error);
      throw error;
    }
  };

  const handleGetWhatsappNumbers = async (brandId) => {
    try {
      const response = await getWhatsappNumbers(brandId);
      setWhatsappNumbers(response);
      return response;
    } catch (error) {
      setError(error);
      throw error;
    }
  };

  const handleGetEmails = async (brandId) => {
    try {
      const response = await getEmailSources(brandId);
      setEmails(response);
      return response;
    } catch (error) {
      setError(error);
      return error;
    }
  };

  const handleGetCampaigns = async (brandId) => {
    try {
      const response = await getCampaigns(brandId);
      setCampaigns(response);
      return response;
    } catch (error) {
      setError(error);
      throw error;
    }
  };

  const handleCreateAutomationSubmit = async (formData, brandId, onSuccess) => {
    console.log("handle entered");

    try {
      setLoading(true);

      if (!userData) {
        await handleGetUserData();
      }

      const userId = userData?.userId;
      console.log(userData);
      console.log(brandId);

      if (!userId || !brandId) {
        throw new Error("Missing required user or brand information");
      }
      const response = await createAutomation(formData, brandId, userId);
      console.log(response);

      const journey = await handleCreateFlow(response.Id);
      console.log(journey);

      if (response.IsSuccess === false) {
        throw new Error(response.Message);
      }

      setAutomations((prev) => [...prev, response]);

      if (onSuccess) {
        onSuccess(journey);
      }

      return response;
    } catch (error) {
      console.error("Submit Error:", error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleCreateFlow = async (automationId) => {
    console.log("id: ", automationId);

    try {
      const payload = {
        AutomationId: automationId,
        NodesJson: "",
        EdgesJson: "",
        ViewportJson: "",
      };
      console.log("payload: ", payload);

      const response = await createFlow(payload);
      console.log("response:", response);

      return response;
    } catch (error) {
      console.error("Submit Error:", error);
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleEditJourneyAutomation = async (id, payload) => {
    try {
      setLoading(true);
      console.log("payload", payload);

      const response = await updateFlow(id, payload);
      /* const response = await editJourneyAutomation(payload); */
      console.log("response", response);

      if (response && response.Id) {
        // Update local state
        setAutomations((prev) =>
          prev.map((auto) => (auto.Id === payload.Id ? response : auto))
        );
      } else {
        console.error("Received invalid response:", response);
        // Handle the invalid response accordingly, e.g., show an error message
      }

      return response;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleEditAutomation = async (id, payload) => {
    try {
      setLoading(true);
      const response = await updateAutomation(id, payload);

      // Update local state
      setAutomations((prev) =>
        prev.map((auto) => {
          if (auto && auto.Id === payload.Id) {
            return response && response.Id ? response : auto; // Only replace if response is valid
          }
          return auto;
        })
      );

      return response;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleAddJourneyActions = async (payload) => {
    try {
      setLoading(true);
      const response = await addJourneyActions(payload);

      // Update current automation if actions were added to it
      if (currentAutomation?.Id === payload.automationId) {
        setCurrentAutomation((prev) => ({
          ...prev,
          Actions: [...(prev.Actions || []), response],
        }));
      }

      return response;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteJourneyActions = async (actionId, automationId) => {
    try {
      setLoading(true);
      await deleteJourneyActions(actionId);

      // Update current automation if action was deleted from it
      if (currentAutomation?.Id === automationId) {
        setCurrentAutomation((prev) => ({
          ...prev,
          Actions: prev.Actions.filter((action) => action.Id !== actionId),
        }));
      }
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteJourneyAutomation = async (automationId) => {
    try {
      setLoading(true);
      await deleteAutomation(automationId);

      // Update local state
      setAutomations((prev) => prev.filter((auto) => auto.Id !== automationId));

      // Clear current automation if it was deleted
      if (currentAutomation?.Id === automationId) {
        setCurrentAutomation(null);
      }
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleGetJourneyAutomations = async (brandId) => {
    try {
      setLoading(true);
      /* const response = await getJourneyAutomations(brandId); */
      const response = await getAutomationsBybrandId(brandId);
      setAutomations(response);
      return response;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleGetFlow = async (automationId) => {
    try {
      setLoading(true);
      /* const response = await getJourneyAutomation(automationId); */
      const response = getFlowByAutomationId(automationId);
      setCurrentAutomation(response);
      return response;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    userData,
    brandSegments,
    automations,
    currentAutomation,
    isLoading,
    error,
    whatsappNumbers,
    campaigns,
    emails,
    handleGetEmails,
    handleGetCampaigns,
    handleGetWhatsappNumbers,
    handleGetUserData,
    handleGetBrandSegments,
    handleCreateAutomationSubmit,
    handleEditJourneyAutomation,
    handleEditAutomation,
    handleAddJourneyActions,
    handleDeleteJourneyActions,
    handleDeleteJourneyAutomation,
    handleGetJourneyAutomations,
    handleGetFlow,
  };
}
