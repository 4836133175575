import "./App.css";
import Router from "./routerfolder/Router";

function App() {
  return (
        <Router />
  );
}

export default App;
