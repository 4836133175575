import React from 'react';
import { Handle, useHandleConnections } from '@xyflow/react';

const CustomHandle = ({ connectionCount = Infinity, ...props }) => {
    const connections = useHandleConnections({
        type: props.type,
    });

    return (
        <Handle
            {...props}
            isConnectable={connections.length < connectionCount}
        />
    );
};

export default CustomHandle;
