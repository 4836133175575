import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ReactFlow,
  Controls,
  MiniMap,
  Background,
  addEdge,
  useNodesState,
  useEdgesState,
  ControlButton,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import AddNodeButton from '../components/flow/AddNodeButton';
import useSelectedNode from "../hooks/flowhooks/useSelectedNode";
import useAutomations from '../hooks/automationhooks/useAutomations';
import { Button } from '@mui/material';
import ROUTES from '../routerfolder/Routes';
import AddNodeContext from '../contexts/AddNodeContext';
import ActionEdge from '../components/flow/edges/ActionEdge';
import useIdGenerator from '../hooks/flowhooks/useIdGenerator';
import useNodeManager from '../hooks/flowhooks/useNodeManager';
import useSave from '../hooks/flowhooks/useSave';
import getNodeTypes from '../utils/workflow/getNodeTypes';
import ConditionEdge from '../components/flow/edges/ConditionEdge';
import { LightMode, DarkMode } from '@mui/icons-material';

export default function Workflow() {
  const { automationId, brandId } = useParams();
  const { handleGetFlow, handleEditJourneyAutomation, isLoading } = useAutomations();
  const { onNodeClick, onPaneClick } = useSelectedNode();
  const [tempAutomation, setTempAutomation] = useState(null);
  const [rfInstance, setRfInstance] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [colorMode, setColorMode] = useState('dark');
  const generateId = useIdGenerator();
  const { addNodeBetweenNodes, addNode, onNodesDelete, editNode, cleanupOrphanedExitNodes } = useNodeManager(nodes, edges, setNodes, setEdges, generateId);
  const navigate = useNavigate();
  const nodeTypes = getNodeTypes();
  const initialized = useRef(false);
  const edgeTypes = {
    actionEdge: ActionEdge,
    conditionEdge: ConditionEdge
  };

  const onSave = useSave(rfInstance, tempAutomation, automationId, handleEditJourneyAutomation); // TODO: handleEditJourneyAutomation implementation
  
  useEffect(() => {
    const loadAutomation = async () => {
      try {
        /*   const automations = await handleGetJourneyAutomations(brandId);
        const targetAutomation = automations.find(
          auto => auto.Id === Number(automationId)
          );
          */
        const targetAutomation = await handleGetFlow(automationId)
        
        if (!targetAutomation) {
          console.error('Automation not found');
          navigate(ROUTES.ROOT);
          return;
        }

        setTempAutomation(targetAutomation);

        if (targetAutomation && !initialized.current) {
          setNodes(targetAutomation.nodes || []);
          setEdges(targetAutomation.edges || []);
          initialized.current = true;
        }
      } catch (error) {
        console.error('Failed to load automation:', error);
      }
    };

    loadAutomation();
  }, []);

  const onEdgesDelete = useCallback(
    (deletedEdges) => {
      setEdges((currentEdges) => {
        // Remove the deleted edges
        const updatedEdges = currentEdges.filter(
          (edge) => !deletedEdges.some((deletedEdge) => deletedEdge.id === edge.id)
        );
        return updatedEdges;
      });

      // Defer the cleanup to after all deletions are processed
      setTimeout(() => {
        cleanupOrphanedExitNodes();
      }, 0);
    },
    [setEdges, cleanupOrphanedExitNodes]
  );

  const onConnect = useCallback(
    (params) => {
      const { sourceHandle, source } = params;
      const sourceNode = nodes.find(node => node.id === source);
      const isConditionNode = sourceNode?.type === 'conditionNode';

      let edgeData = {
        ...params,
        type: isConditionNode ? 'conditionEdge' : 'actionEdge',
        data: {}
      };

      if (isConditionNode) {
        let label = '';
        if (sourceHandle === 'yes') {
          label = 'Yes';
        } else if (sourceHandle === 'no') {
          label = 'No';
        }
        edgeData.data.label = label;
      }

      setEdges((eds) => addEdge(edgeData, eds));
    },
    [setEdges, nodes]
  );

  const toggleColorMode = useCallback(() => {
    setColorMode(prevMode => prevMode === 'dark' ? 'light' : 'dark');
  }, []);

  if (isLoading || !tempAutomation) {
    return <div>Loading...</div>;
  }

  return (
    <AddNodeContext.Provider value={{ addNodeBetweenNodes, addNode, editNode }}>
      <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>

        <Button
          style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
          onClick={onSave}
          variant="contained"
        >
          Save {tempAutomation.Name}
        </Button>
        <Button
          style={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}
          onClick={() => navigate(`/journeys/${brandId}`)}
          variant="contained"
        >
          Go Back
        </Button>

        <AddNodeButton nodesExist={nodes.length > 0} />

        {/* React Flow component */}
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgesDelete={onEdgesDelete}
          onNodesDelete={onNodesDelete}
          colorMode={colorMode}
          onConnect={onConnect}
          onInit={setRfInstance}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodeClick={onNodeClick}
          onPaneClick={onPaneClick}
          fitView
        >
          <Controls orientation="horizontal">
            <ControlButton onClick={toggleColorMode} title={`Switch to ${colorMode === 'dark' ? 'light' : 'dark'} mode`}>
              {colorMode === 'dark' ? <LightMode /> : <DarkMode />}
            </ControlButton>
          </Controls>
          <MiniMap />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      </div>
    </AddNodeContext.Provider>
  );
}
