// hooks/flowhooks/useSave.js

import { useCallback } from "react";
import { onSave } from "../../utils/workflow/saveUtils";
import { isTriggerNode, isActionNode } from "../../utils/workflow/nodeUtils";

export default function useSave(
  rfInstance,
  automation,
  id,
  handleEditJourneyAutomation
) {
  const onSaveCallback = useCallback(() => {
    if (rfInstance && automation) {
      const flow = rfInstance.toObject();
      const { nodes } = flow;

      // Save flow to localStorage using saveUtils
      onSave(flow);

      // Extract trigger nodes
      const triggerNodes = nodes.filter((node) => isTriggerNode(node.type));
      if (triggerNodes.length === 0) {
        alert(
          "No trigger node found. Please add a trigger to start the automation."
        );
        return;
      }
      // const trigger = triggerNodes[0].data;

      // Extract action nodes
      const actionNodes = nodes.filter((node) => isActionNode(node.type));
      // const actions = actionNodes.map((node) => ({
      //   Id: node.data.Id || 0,
      //   Type: node.type,
      //   To: node.data.sendTo || null,
      //   Subject: node.data.subject || null,
      //   Body: node.data.body || null,
      //   Attachments: node.data.attachments || null,
      // }));

      // Construct the payload
      /*      const payload = {
        Id: automation.Id,
        BrandId: automation.BrandId,
        UserId: automation.UserId,
        Name: automation.Name,
        Description: automation.Description,
        Priority: automation.Priority,
        Status: automation.Status,
        Schedule: automation.Schedule,
        Metrics: automation.Metrics,
        Audiences: automation.Audiences,
        Settings: automation.Settings,
        Trigger: JSON.stringify(trigger),
        Actions: actions,
      };
 */

      // Call handleEditJourneyAutomation with the payload
      handleEditJourneyAutomation(id,flow)
        .then((response) => {
          // Handle success
          console.log("Automation updated successfully", response);
          alert("Automation saved successfully.");
        })
        .catch((error) => {
          // Handle error
          console.error("Error updating automation", error);
          alert("Failed to save automation. Please try again.");
        });
    }
  }, [rfInstance, automation, handleEditJourneyAutomation]);

  return onSaveCallback;
}
