import React from 'react'
import ActionNodeTemplate from '../templates/ActionNodeTemplate'

export default function WaitNode({ id, type, data }) {
    return (
        <ActionNodeTemplate id={id} type={type} data={data}>
            <div>Time: {data.time} seconds</div>
            <div>
                Days to Skip: {data.daysToSkip && data.daysToSkip.join(', ')}
            </div>
        </ActionNodeTemplate>
    )
}
