// components/flow/nodes/NewLeadNode.js
import React from 'react';
import TriggerNodeTemplate from '../templates/TriggerNodeTemplate';

const NewLeadNode = ({ id, type, data }) => {
    const campaigns = data.campaignSource
        ?.map((campaign) => campaign.label) // Extract the label of each campaign
        .join(', '); // Join the labels with a comma

    return (
        <TriggerNodeTemplate id={id} type={type} data={data}>
            <div>Campaign(s): {campaigns || 'None'}</div> {/* Display 'None' if no campaigns */}
            <div>Lead Type: {data.leadType?.label || 'N/A'}</div> {/* Safely access leadType */}
        </TriggerNodeTemplate>
    );
};

export default NewLeadNode;
