import MenuIcon from '@mui/icons-material/Menu';
import { Autocomplete, Avatar, Box, IconButton, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function Header({ brands }) {
    const navigate = useNavigate();
    const { brandId } = useParams();

    const handleBrandChange = (event, newValue) => {
        if (newValue) {
            navigate(`/journeys/${newValue.BrandId}`);
        } else {
            navigate('/journeys');
        }
    };

    const currentBrand = brands?.find(brand => brand.BrandId === brandId) || null;

    return (
        <Box sx={{ display: "flex", flexDirection: "row", marginTop: 2, width: "98vw" }}>
            <MenuIcon sx={{ padding: "2vh", fontSize: "1.5vw", color: "gray" }} />
            <Autocomplete
                options={brands || []}
                sx={{ width: "9vw" }}
                value={currentBrand}
                onChange={handleBrandChange}
                getOptionLabel={(option) => option?.BrandName || ''}
                isOptionEqualToValue={(option, value) => option?.BrandId === value?.BrandId}
                renderInput={(params) => <TextField {...params} label="Select Brand" />}
            />
            <Tooltip title="My Profile">
                <IconButton sx={{ marginLeft: "auto", alignItems: "center" }}>
                    <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                </IconButton>
            </Tooltip>
        </Box>
    )
}
