// components/AutomationDetails.js
import React from 'react';
import {
    Collapse,
    Box,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';

export default function AutomationDetails({ open, details }) {
    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                            Details
                        </Typography>
                        <Table size="small" aria-label="details">
                            <TableBody>
                                {/*A lot of this is metadata that will be given from the back end*/}
                                {Object.entries({
                                    Name: details.name,
                                    Description: details.description,
                                    Priority: details.priority,
                                    Tags: details.tags ? details.tags.join(', ') : 'N/A',
                                    'Trigger Type': details.trigger,
                                    'Trigger Details': details.triggerDetails,
                                    'Schedule Start': details.scheduleStart, //change this obviously
                                    'Schedule End': details.scheduleEnd,
                                    Actions: details.actions,
                                    Metrics: details.metrics,
                                    Settings: details.settings,
                                }).map(([label, value]) => (
                                    <TableRow key={label}>
                                        <TableCell component="th" scope="row">
                                            {label}
                                        </TableCell>
                                        <TableCell>{value || 'N/A'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
}
