import React, { useEffect, useState } from 'react';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Grid,
    Box,
    Typography,
    FormHelperText,
} from '@mui/material';
import FormTemplate from './FormTemplate';
import { SingleInputTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputTimeRangeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ConditionBuilder from '../components/ConditionBuilder';

const NodesDynamicForm = ({ formFields, title, onSubmit, initialData = {} }) => {
    const [valid, setValid] = useState(false);
    const [touched, setTouched] = useState([]);
    const [formData, setFormData] = useState(() => {
        const initialState = formFields.reduce((acc, field) => {
            if (field.type === 'checkboxGroup') {
                acc[field.id] = [];
            } else if (field.type === 'checkbox') {
                acc[field.id] = field.defaultValue || false;
            } else {
                acc[field.id] = '';
            }
            return acc;
        }, {});

        return {
            ...initialState,
            ...Object.fromEntries(
                Object.entries(initialData).map(([key, value]) => {
                    if (Array.isArray(value)) {
                        if (value.length > 0 && value[0].hasOwnProperty('value')) {
                            return [key, value];
                        }
                        const field = formFields.find((f) => f.id === key);
                        if (field && field.type === 'checkboxGroup') {
                            const valuesWithLabels = value.map((v) => {
                                const option = field.options.find((opt) => opt.value === v);
                                return {
                                    value: v,
                                    label: option ? option.label : v.toString(),
                                };
                            });
                            return [key, valuesWithLabels];
                        }
                    }
                    return [key, value ?? initialState[key]];
                })
            ),
        };
    });

    const handleTouched = (field) => {
        setTouched((prev) => ({
            ...prev, [field]: true
        }))
    }

    const handleChange = (field) => (event) => {
        if (field.type === 'checkboxGroup') {
            const { value, checked } = event.target;
            setFormData((prevData) => {
                const currentValues = Array.isArray(prevData[field.id]) ? prevData[field.id] : [];

                const updatedValues = checked
                    ? [...currentValues, value]
                    : currentValues.filter((v) => v !== value);
                return { ...prevData, [field.id]: updatedValues };
            });
        } else if (field.type === 'checkbox') {
            setFormData({
                ...formData,
                [field.id]: event.target.checked,
            });
        } else {
            setFormData({
                ...formData,
                [field.id]: event.target.value,
            });
        }
    };

    const handleSubmit = () => {
        const updatedFormData = { ...formData };

        formFields.forEach((field) => {
            if (field.type === 'select' || field.type === 'checkboxGroup') {
                if (Array.isArray(updatedFormData[field.id])) {
                    // For checkboxGroup, map selected values to include their labels
                    updatedFormData[field.id] = updatedFormData[field.id].map((value) => {
                        const option = field.options.find((opt) => opt.value === value);
                        return {
                            value,
                            label: option ? option.label : value, // Include the label if found
                        };
                    });
                } else if (updatedFormData[field.id]) {
                    // For select, map the selected value to include its label
                    const option = field.options.find((opt) => opt.value === updatedFormData[field.id]);
                    updatedFormData[field.id] = {
                        value: updatedFormData[field.id],
                        label: option ? option.label : updatedFormData[field.id], // Include the label if found
                    };
                }
            }
        });

        // Pass the updated form data to the onSubmit handler
        onSubmit(updatedFormData);
    };

    const handleSelectAll = (field) => (event) => {
        const { checked } = event.target;
        setFormData((prev) => ({
            ...prev,
            [field.id]: checked ? field.options.map((option) => option.value) : [],
        }));
    };

    const isFormValid = () => {
        return formFields.every((field) => {
            if (field.required) {
                const value = formData[field.id];
                if (field.type === 'checkboxGroup') {
                    return Array.isArray(value) && value.length > 0;
                }
                return Boolean(value);
            }
            return true;
        });
    };

    useEffect(() => {
        setValid(isFormValid())
    }, [formData])

    return (
        <FormTemplate title={title} handleSubmit={handleSubmit} isValid={valid}>
            <Grid
                container
                spacing={2}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    padding: '16px'
                }}
            >
                {formFields.map((field) => {
                    switch (field.type) {
                        case 'text':
                        case 'email':
                        case 'number':
                            return (
                                <Grid item xs={12} key={field.id}>
                                    <TextField
                                        label={field.label}
                                        type={field.type}
                                        value={formData[field.id]}
                                        onChange={handleChange(field)}
                                        required
                                        onBlur={(e) => {
                                            handleTouched(field.id)
                                        }}
                                        error={!formData[field.id] && touched[field.id]}
                                        helperText={touched[field.id] && !formData[field.id] ? 'Field is required' : ''}
                                        fullWidth
                                    />
                                </Grid>
                            );
                        case 'select':
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    key={field.id}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <FormControl sx={{ minWidth: 300 }}
                                        onBlur={(e) => {
                                            handleTouched(field.id)
                                        }}
                                        error={!formData[field.id] && touched[field.id]}
                                        required>
                                        <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>
                                        <Select
                                            labelId={`${field.id}-label`}
                                            id={`${field.id}-select`}
                                            value={formData[field.id] || ''}
                                            label={field.label}
                                            onChange={handleChange(field)}


                                        >
                                            <MenuItem key={`${field.id}-empty`} value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {field.options
                                                ?.filter(
                                                    (option) =>
                                                        option && option.value != null && option.label != null
                                                )
                                                .map((option) => (
                                                    <MenuItem key={`${field.id}-${option.value}`} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        <FormHelperText>{touched[field.id] && !formData[field.id] ? 'Field is required' : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            );
                        case 'checkbox':
                            return (
                                <Grid item xs={12} key={field.id} sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData[field.id]}
                                                onChange={handleChange(field)}
                                            />
                                        }
                                        label={field.label}
                                    />
                                </Grid>
                            );
                        case 'checkboxGroup':
                            return (
                                <Grid item xs={12} key={field.id}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            maxWidth: '800px',
                                            margin: '0 auto',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                textAlign: 'center',
                                                color: 'text.secondary',
                                            }}
                                        >
                                            {field.label}
                                        </Typography>

                                        <FormControl component="fieldset" sx={{ width: '100%' }}>
                                            {/* Conditional Rendering */}
                                            {field.options.length === 0 ? (
                                                // Display message when there are no options
                                                <Typography
                                                    variant="body1"
                                                    align="center"
                                                    color="text.secondary"
                                                    sx={{ mt: 2 }}
                                                >
                                                    No data found!
                                                </Typography>
                                            ) : (
                                                // Render the checkbox components
                                                <>
                                                    {/* Centered "Select All" Checkbox */}
                                                    <Grid container justifyContent="center" sx={{ mb: 2 }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        Array.isArray(formData[field.id]) &&
                                                                        formData[field.id].length === field.options.length
                                                                    }
                                                                    indeterminate={
                                                                        Array.isArray(formData[field.id]) &&
                                                                        formData[field.id].length > 0 &&
                                                                        formData[field.id].length < field.options.length
                                                                    }
                                                                    onChange={handleSelectAll(field)}
                                                                />
                                                            }
                                                            label="Select All"
                                                        />
                                                    </Grid>

                                                    {/* Checkbox Grid */}
                                                    <Grid container spacing={1}>
                                                        {field.options.map((option) => {
                                                            const isChecked =
                                                                Array.isArray(formData[field.id]) &&
                                                                formData[field.id].includes(option.value);
                                                            return (
                                                                <Grid item xs={12} sm={6} md={4} key={option.value}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={isChecked}
                                                                                onChange={(e) => {
                                                                                    const { checked } = e.target;
                                                                                    setFormData((prev) => {
                                                                                        const currentValues = Array.isArray(prev[field.id])
                                                                                            ? prev[field.id]
                                                                                            : [];
                                                                                        const newValues = checked
                                                                                            ? [...currentValues, option.value]
                                                                                            : currentValues.filter((v) => v !== option.value);

                                                                                        return {
                                                                                            ...prev,
                                                                                            [field.id]: newValues,
                                                                                        };
                                                                                    });
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={option.label}
                                                                        sx={{
                                                                            alignItems: 'flex-start',
                                                                            '.MuiFormControlLabel-label': {
                                                                                wordBreak: 'break-word',
                                                                                overflowWrap: 'break-word',
                                                                                hyphens: 'auto',
                                                                            },
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </>
                                            )}

                                        </FormControl>
                                    </Box>
                                </Grid>
                            );

                        case 'timeRange':
                            return (
                                <Grid item xs={12} key={field.id}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            maxWidth: '800px',
                                            margin: '0 auto',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                            mt: 2,
                                            mb: 2,
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                textAlign: 'center',
                                                color: 'text.secondary',
                                            }}
                                        >
                                            {field.label}
                                        </Typography>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <SingleInputTimeRangeField
                                                label="Select Time Range"
                                                value={[
                                                    formData[field.id]?.[0]
                                                        ? dayjs(formData[field.id][0], 'HH:mm')
                                                        : null,
                                                    formData[field.id]?.[1]
                                                        ? dayjs(formData[field.id][1], 'HH:mm')
                                                        : null,
                                                ]}
                                                onChange={(newValue) => {
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        [field.id]: newValue
                                                            ? [
                                                                newValue[0]?.format('HH:mm'),
                                                                newValue[1]?.format('HH:mm'),
                                                            ]
                                                            : ['00:00', '00:00'],
                                                    }));
                                                }}
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: '400px',
                                                    margin: '0 auto',
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                            );
                        case 'conditionBuilder':
                            return (
                                <Grid item xs={12} key={field.id}>
                                    <ConditionBuilder
                                        value={formData[field.id]}
                                        onChange={(value) => setFormData({
                                            ...formData,
                                            [field.id]: value
                                        })}
                                        conditionTypes={field.conditionTypes}
                                    />
                                </Grid>
                            );
                        default:
                            return null;
                    }
                })}
            </Grid>
        </FormTemplate>
    );
};

export default NodesDynamicForm;
