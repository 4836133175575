import { useRef } from "react";

export default function useIdGenerator() {
  const idCounter = useRef(0);

  const generateId = (type = "node") => {
    idCounter.current += 1;
    return `${type}_${idCounter.current}`;
  };

  return generateId;
}
